<script setup lang="ts">
import Protected from '@/features/auth/Protected.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps({
  authorized: {
    type: Boolean,
    default: false
  },
});

const links = [
  {
    name: t('admin.users'),
    link: 'users'
  },
  {
    name: t('admin.accounts'),
    link: 'accounts'
  }
];

const route = useRoute();

const pathname = route.path;
</script>

<template>
  <div class="h-full bg-[#1E1E1E]">
    <Protected :authorized="authorized">
      <div class="h-full w-full p-10 text-white flex flex-row relative">
        <div class="col-span-1 h-full hidden lg:block min-w-[200px]">
          <h1 class="text-3xl font-bold focus:bg-white truncate">
            {{ $t('admin.title') }}
          </h1>
          <div class="mt-4">
            <a
              v-for="link in links"
              :key="link.link"
              :href="link.link"
            >
              <div
                :class="{ 'bg-gray-700': pathname.includes(link.link) }"
                class="
                  text-xl
                  w-full
                  max-w-200
                  hover:bg-gray-700
                  p-2
                  rounded-md"
              >
                {{ link.name }}
              </div>
            </a>
          </div>
        </div>
        <div class="w-full ml-5 mb-5 flex justify-center">
          <slot />
        </div>
      </div>
    </Protected>
  </div>
</template>
