<script setup lang="ts">
const props = defineProps({
  authorized: {
    type: Boolean,
    required: true,
  },
});
</script>

<template>
  <div class="h-full">
    <slot v-if="props.authorized" />
    <div
      v-else
      class="h-full flex flex-col items-center justify-center text-white text-center px-10"
    >
      <h1 class="text-4xl font-bold">
        401 <br>
        Not authorized
      </h1>
      <p>
        You are missing access to this page. Contact your administrator if you
        wish to gain access.
      </p>
    </div>
  </div>
</template>
